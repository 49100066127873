var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('my-nav-bar', {
    attrs: {
      "title": "工作报表",
      "left-text": "返回",
      "left-arrow": ""
    }
  }), _c('div', {
    staticClass: "filter-container"
  }, [_c('van-cell', {
    attrs: {
      "title": "门店",
      "value": _vm.storeName,
      "is-link": "",
      "center": ""
    },
    on: {
      "click": function click($event) {
        _vm.showAction = true;
      }
    }
  }), _c('van-cell', {
    attrs: {
      "title": "开始日期",
      "value": _vm.query.start_date,
      "is-link": "",
      "center": ""
    },
    on: {
      "click": function click($event) {
        return _vm.handleShowDatePicker('start');
      }
    }
  }), _c('van-cell', {
    attrs: {
      "title": "结束日期",
      "value": _vm.query.end_date,
      "is-link": "",
      "center": ""
    },
    on: {
      "click": function click($event) {
        return _vm.handleShowDatePicker('end');
      }
    }
  })], 1), _c('div', {
    staticClass: "main-container"
  }, [_c('pick-data', {
    staticStyle: {
      "margin-bottom": "10px"
    },
    attrs: {
      "pick-data": _vm.workReportData.pick_data
    }
  }), _c('mall-data', {
    staticStyle: {
      "margin-bottom": "10px"
    },
    attrs: {
      "mall-data": _vm.workReportData.mall_data
    }
  }), _c('handle-data', {
    attrs: {
      "handle-data": _vm.workReportData.handle_data
    }
  })], 1), _c('van-popup', {
    attrs: {
      "position": "bottom"
    },
    model: {
      value: _vm.showDatePicker,
      callback: function callback($$v) {
        _vm.showDatePicker = $$v;
      },
      expression: "showDatePicker"
    }
  }, [_c('van-datetime-picker', {
    attrs: {
      "type": "date",
      "title": "选择日期"
    },
    on: {
      "confirm": _vm.chooseDate,
      "cancel": function cancel($event) {
        _vm.showDatePicker = false;
      }
    },
    model: {
      value: _vm.currentDate,
      callback: function callback($$v) {
        _vm.currentDate = $$v;
      },
      expression: "currentDate"
    }
  })], 1), _c('van-action-sheet', {
    attrs: {
      "actions": _vm.actions
    },
    on: {
      "select": _vm.handleSelect
    },
    model: {
      value: _vm.showAction,
      callback: function callback($$v) {
        _vm.showAction = $$v;
      },
      expression: "showAction"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }