<template>
  <div class="mall-data-container">
    <div id="mall-data" />
    <div v-if="mallData" class="order-data">
      <div>门店订单：{{ mallData.order_data.store.count }}单，共{{ mallData.order_data.store.amount }}元</div>
      <div>商城订单：{{ mallData.order_data.mall.count }}单，共{{ mallData.order_data.mall.amount }}元</div>
    </div>
  </div>
</template>

<script>
import 'echarts/lib/component/title'
import 'echarts/lib/component/toolbox'
import * as echarts from 'echarts'
export default {
  props: {
    mallData: {
      type: Object,
      default: function() {}
    }
  },
  data() {
    return {
      myChart: null
    }
  },
  watch: {
    mallData(n) {
      this.render()
    }
  },
  mounted() {
    this.myChart = echarts.init(document.getElementById('mall-data'))
  },
  methods: {
    render() {
      const color = [
        '#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc'
      ]
      const option = {
        title: {
          text: '商城数据',
          textStyle: {
            color: '#323232',
            fontWeight: 400,
            fontFamily: 'Microsoft YaHei',
            fontSize: 15
          }
        },
        grid: { top: '50%' },
        color,
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross'
          }
        },
        legend: {
          left: '23%',
          type: 'scroll',
          data: this.mallData.mall_titles
        },
        xAxis: {
          data: this.mallData.mall_names,
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: [{}, {}, {}, {}],
        series: this.mallData.data
      }
      this.myChart.setOption(option)
    }
  }
}
</script>

<style lang="scss" scoped>
  .mall-data-container {
    background-color: #fff;
    border-radius: 10px;
    padding: 10px;
  }
  #mall-data {
    height: 400px;
  }
  .order-data {
    color: #323232;
  }
</style>
