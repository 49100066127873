var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mall-data-container"
  }, [_c('div', {
    attrs: {
      "id": "mall-data"
    }
  }), _vm.mallData ? _c('div', {
    staticClass: "order-data"
  }, [_c('div', [_vm._v("门店订单：" + _vm._s(_vm.mallData.order_data.store.count) + "单，共" + _vm._s(_vm.mallData.order_data.store.amount) + "元")]), _c('div', [_vm._v("商城订单：" + _vm._s(_vm.mallData.order_data.mall.count) + "单，共" + _vm._s(_vm.mallData.order_data.mall.amount) + "元")])]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }