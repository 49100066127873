<template>
  <div>
    <my-nav-bar
      title="工作报表"
      left-text="返回"
      left-arrow
    />
    <div class="filter-container">
      <van-cell title="门店" :value="storeName" is-link center @click="showAction = true" />
      <van-cell title="开始日期" :value="query.start_date" is-link center @click="handleShowDatePicker('start')" />
      <van-cell title="结束日期" :value="query.end_date" is-link center @click="handleShowDatePicker('end')" />
    </div>
    <div class="main-container">
      <pick-data :pick-data="workReportData.pick_data" style="margin-bottom: 10px" />
      <mall-data :mall-data="workReportData.mall_data" style="margin-bottom: 10px" />
      <handle-data :handle-data="workReportData.handle_data" />
    </div>
    <van-popup v-model="showDatePicker" position="bottom">
      <van-datetime-picker
        v-model="currentDate"
        type="date"
        title="选择日期"
        @confirm="chooseDate"
        @cancel="showDatePicker = false"
      />
    </van-popup>
    <van-action-sheet v-model="showAction" :actions="actions" @select="handleSelect" />
  </div>
</template>

<script>
import myNavBar from '@/components/my-nav-bar'
import { currentDate, toDateString } from '@/utils'
import PickData from './components/pick-data'
import { getWorkReportData } from '@/api/work_report'
import MallData from './components/mall-data'
import HandleData from './components/handle-data'
import { getStores } from '@/api/home'
export default {
  components: { myNavBar, PickData, MallData, HandleData },
  data() {
    MallData
    return {
      query: {
        store_id: '',
        start_date: currentDate(),
        end_date: currentDate()
      },
      currentDate: new Date(),
      showDatePicker: false,
      showAction: false,
      storeName: '',
      actions: [],
      workReportData: {},
      chooseDateType: ''
    }
  },
  created() {
    this.getData()
    getStores().then(res => {
      res.data.map(store => {
        this.actions.push({
          value: store.id,
          name: store.name
        })
      })
    })
  },
  methods: {
    getData() {
      this.beginLoad()
      getWorkReportData(this.query).then(res => {
        this.endLoad()
        this.workReportData = res.data
      })
    },
    handleShowDatePicker(type) {
      this.showDatePicker = true
      this.chooseDateType = type
    },
    chooseDate(date) {
      if (this.chooseDateType === 'start') {
        this.query.start_date = toDateString(date)
      } else {
        this.query.end_date = toDateString(date)
      }
      this.showDatePicker = false
      this.getData()
    },
    handleSelect(action) {
      this.query.store_id = action.value
      this.storeName = action.name
      this.showAction = false
      this.getData()
    }
  }
}
</script>

<style lang="scss" scoped>
  .filter-container {
    position: fixed;
    left: 0;
    right: 0;
    top: 46px;
    z-index: 9;
  }
  .main-container {
    margin-top: 188px;
    padding: 0 10px;
    padding-bottom: calc(env(safe-area-inset-bottom) + 10px);
    padding-bottom: calc(constant(safe-area-inset-bottom) + 10px);
  }
</style>
