<template>
  <div id="pick-data" />
</template>

<script>
import * as echarts from 'echarts'
export default {
  props: {
    pickData: {
      type: Object,
      default: function() {}
    }
  },
  data() {
    return {
      myChart: null
    }
  },
  watch: {
    pickData(n) {
      this.render()
    }
  },
  mounted() {
    this.myChart = echarts.init(document.getElementById('pick-data'))
  },
  methods: {
    render() {
      const color = [
        '#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc'
      ]
      const option = {
        title: {
          text: '拣货数据',
          textStyle: {
            color: '#323232',
            fontWeight: 400,
            fontFamily: 'Microsoft YaHei',
            fontSize: 15
          }
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross'
          }
        },
        color,
        legend: {
          left: '23%',
          type: 'scroll',
          data: this.pickData.pick_titles
        },
        xAxis: {
          data: this.pickData.pick_names
        },
        yAxis: [{}, {}, {}],
        series: this.pickData.data
      }
      this.myChart.setOption(option)
    }
  }
}
</script>

<style lang="scss" scoped>
  #pick-data {
    height: 300px;
    background-color: #fff;
    border-radius: 10px;
    padding: 10px;
  }
</style>
